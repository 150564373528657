<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
      <div class="row mb-4">
        <div class="col-sm-12 mb-4">
          <div class="card text-left">
            <div class="card-header">
              <div class="row">
                <div class="col-12 col-md-9 col-lg-10">
                  <div class="breadcrumb mb-2 custom-top-padding">
                    <h1 class="mb-0"><strong>Lista de Medicos</strong></h1>
                  </div>
                </div>
                 <!--<div class="col-sm-3 offset-sm-3 d-flex justify-content-end align-items-center pt-2">
                  <div class="text-right">
                    <label class="switch switch-success mr-3">
                      <input type="checkbox" id="filter"
                      :checked="estado"
                      v-model="estado"
                      @change="listarMedicos">
                      <span class="slider"></span>
                      <span>{{ labelActivos }}</span>
                    </label>
                  </div>
                </div>-->
                <div class="col-12 col-sm-3 col-md-2 text-center text-md-right">
                  <button class="btn btn-info btn-responsive" @click="goToAtencionPaciente">
                    Pacientes Atendidos
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row py-3" v-if="!estado">
                <div class="col-4">
                  <div class="btn-group">
                      <b-form-select
                        v-model="porPagina"
                        :options="paginaOpciones"
                      ></b-form-select>
                      <span class="pt-2 ml-1"> Registros</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3 p-3" v-for="(medico, index) in medicos" :key="index">
                  <div class="col-12 p-3 bg-gray border-radius" style="cursor: pointer;"
                  @click="onClickEditarIcon(medico.id, medico.tipo_turno_medico_id)">
                    <div class="col-12 p-0">
                    </div>
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="logo">
                            <img style="border-radius: 120px; height: auto;"
                              v-if="medico.imagen"
                              :src="medico.imagen">
                            <img style="border-radius: 120px; height: auto;"
                              v-else
                              :src="require('@/assets/images/faces/img_default.png')">
                          </div>
                        </div>
                        <div class="col-sm-7 small-content">
                          <div class="col-sm-12 pl-0 turno">
                            <strong v-if="medico.turno1">
                              <span>
                                Turno 1: {{ labelTurno1(medico) }}
                              </span>
                            </strong>
                            <br>
                            <strong v-if="medico.turno2">
                              <span>
                                Turno 2: {{ labelTurno2(medico) }}
                              </span>
                            </strong>
                          </div>
                          <div class="col-sm-12 small-content text-right" style="font-size:11px;
                            margin-block-start: auto; color: gray;">
                            <strong>Atendido: </strong>{{ medico.atendidos }}
                            <br>
                            <strong>Pendiente: </strong> {{ medico.pendientes }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <strong> {{ medico.nombre }} </strong>
                          <br>
                          <strong style="color: orange;">
                            {{ nombreEspecialidad(JSON.parse(medico.especialidad.toString())) }}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8"></div>
                <div class="col-sm-4">
                  <b-pagination
                    v-model="paginaActual"
                    :total-rows="totalFilas"
                    :per-page="porPagina"
                    class="my-0 float-right"
                    @input="listarMedicos"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'index',
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {
    await this.listarMedicos();
    this.isLoading = false;
  },
  computed: {
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    ...mapState('ConsultaExternaList', [
      'estado',
      'busqueda',
      'paginaActual',
      'totalFilas',
      'medicos',
      'paginaOpciones',
    ]),
    ...mapFields('ConsultaExternaList', [
      'estado',
      'porPagina',
      'busqueda',
    ]),
    // turno2() {},
  },
  methods: {
    // eslint-disable-next-line camelcase
    onClickEditarIcon(id, tipoTurnoMedicoId) {
      this.$router.push({ name: 'consulta-externa-paciente', params: { id: this.encrypt(id), tipo_turno_medico_id: tipoTurnoMedicoId } });
    },
    goToAtencionPaciente() {
      this.$router.push({ name: 'ce-atencion-paciente-index' });
    },
    ...mapActions('ConsultaExternaList', [
      'listarMedicos',
      'resetStateConsultaExterna',
    ]),
    encrypt(id) {
      return btoa(id);
    },
    nombreEspecialidad(especialidades) {
      let nombre = '';
      especialidades.forEach((ele, index) => {
        if (index === 0) {
          nombre = ele.especialidad;
        } else {
          nombre = `${nombre}, ${ele.especialidad}`;
        }
      });
      return nombre;
    },
    labelTurno1(item) {
      let result = null;
      if (item.turno1) {
        switch (item.tipo_turno_medico_id) {
          case 1:
            result = item.turno1;
            break;
          default:
            result = item.tipo_turno_medico;
            break;
        }
      }
      return result;
    },
    labelTurno2(item) {
      // medico.turno2 ? medico.turno2 : 'S/A'
      let result = null;
      if (item.turno2) {
        switch (item.tipo_turno_medico_id) {
          case 1:
            result = item.turno2;
            break;
          default:
            result = item.tipo_turno_medico;
            break;
        }
      }
      return result;
    },
  },
  watch: {
    porPagina() {
      this.listarMedicos();
    },
  },
  beforeDestroy() {
    this.resetStateConsultaExterna();
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgba(232, 232, 232, 0.971);
}
.border-radius {
  border-radius: 10px;
}
.logo {
  margin: 0;
  margin-left: auto;
  display: block;
}
.turno {
  color: green;
  white-space: nowrap;
  font-size: 8px;
}
.custom-top-padding {
  padding-top: 5.53px;
}
@media (max-width: 1222px) {
  .small-content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .turno {
    font-size: 8px;
    white-space: normal;
  }
  .btn-responsive {
    font-size: 11px; /* Ajustar tamaño en pantallas pequeñas */
  }
}

</style>
